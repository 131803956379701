import Vue from 'vue'
import hopscotch from 'hopscotch'
import moment from 'moment'
import {formatDistanceStrict, parseJSON} from 'date-fns'
import {parse} from 'qs'

// Helpers
import '@/lib/icons'
import currency from '@/lib/currency'
import {default as tour} from '@/lib/tour'
import http from '@/lib/http'
import flash from '@/lib/flash'

// Styles
import './styles.scss'

// Instance properties
Vue.prototype.$globalsettings = window.globalsettings
Vue.prototype.$file_path = window.file_path
Vue.prototype.$user = window.user
Vue.prototype.$http = http
Vue.prototype.$account = window.account
Vue.prototype.$flash = flash
Vue.prototype.$query = parse(location.search.replace(/^\?/, ''))

// Filters
Vue.filter('date', (date, format = null) => {
    return moment(date).format(format || window.globalsettings.date_format)
})
Vue.filter('date_diff', date => {
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date)) {
        date = new Date(date)
    } else {
        date = parseJSON(date)
    }
    return formatDistanceStrict(date, new Date)
})
Vue.filter('day_of_week', date => {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][date - 1]
})
Vue.filter('title', text => {
    return text.replace(/(?:^|\s)\S/g, l => l.toUpperCase())
})
Vue.filter('capitalise', text => {
    return text.charAt(0).toUpperCase() + text.substr(1)
})
Vue.filter('currency', (value, currency_code = null) => {
    return currency.format(value, currency_code)
})

// Directives
Vue.directive('loading', { // A temporary directive to dynamically mark buttons as loading
    update(el, {value}) {
        const content = el.getAttribute('data-original-content')
        if (value && content === null) {
            el.setAttribute('data-original-content', el.innerHTML)
            el.style.width = `${el.offsetWidth}px`
            el.disabled = true
            el.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
        } else if (content !== null) {
            el.innerHTML = content
            el.style.width = ''
            el.disabled = false
            el.removeAttribute('data-original-content')
        }
    }
})

// Start tour if enabled
if (hopscotch.getState()) {
    hopscotch.startTour(tour(window.globalsettings))
}

// Initialise app
import App from './App'
window.app = new Vue({
    el: '#app',
    data() {
        return {
            validation: []
        }
    },
    components: {
        'kb-app': App,
        'kb-pages-account-admin-room-types-categories-index': () => import('./pages/account/admin/room-types/categories/index.vue'),
        'kb-pages-account-admin-config-user-log-index': () => import('./pages/account/admin/config/user-log/index.vue'),
        'kb-pages-account-admin-dashboard-index': () => import('./pages/account/admin/dashboard/index.vue'),
        'kb-pages-account-admin-reports-email-logs-index': () => import('./pages/account/admin/reports/email-logs/index.vue'),
        'kb-pages-account-admin-reports-email-logs-_email_id': () => import('./pages/account/admin/reports/email-logs/_email_id.vue'),
        'kb-pages-account-admin-reports-price-enquiries': () => import('./pages/account/admin/reports/price-enquiries.vue'),
        'kb-pages-account-admin-room-types-categories-_category_id-amend': () => import('./pages/account/admin/room-types/categories/_category_id/amend.vue'),
        'kb-pages-account-admin-room-types-categories-new': () => import('./pages/account/admin/room-types/categories/new.vue'),
        'kb-pages-account-admin-room-types-features-index': () => import('./pages/account/admin/room-types/features/index.vue'),
        'kb-pages-account-admin-room-types-features-new': () => import('./pages/account/admin/room-types/features/new.vue'),
        'kb-pages-account-admin-room-types-features-_feature_id-amend': () => import('./pages/account/admin/room-types/features/_feature_id/amend.vue'),
        'kb-pages-account-admin-room-types-options-index': () => import('./pages/account/admin/room-types/options/index.vue'),
        'kb-pages-account-admin-room-types-options-new': () => import('./pages/account/admin/room-types/options/new.vue'),
        'kb-pages-account-admin-room-types-options-_option_id-amend': () => import('./pages/account/admin/room-types/options/_option_id/amend.vue'),
        'kb-pages-account-admin-config-calendar-index': () => import('./pages/account/admin/config/calendar/index.vue'),
        'kb-pages-account-admin-config-emails-blocked-index': () => import('./pages/account/admin/config/emails/blocked/index.vue'),
        'kb-pages-account-admin-config-emails-templates-index': () => import('./pages/account/admin/config/emails/templates/index.vue'),
        'kb-pages-account-admin-config-emails-templates-_template_id-index': () => import('./pages/account/admin/config/emails/templates/_template_id/index.vue'),
        'kb-pages-account-admin-config-emails-templates-_template_id-amend': () => import('./pages/account/admin/config/emails/templates/_template_id/amend.vue'),
        'kb-pages-account-admin-config-globalsettings-index': () => import('./pages/account/admin/config/globalsettings/index.vue'),
        'kb-pages-account-admin-reports-unassigned': () => import('./pages/account/admin/reports/unassigned.vue'),
        'kb-pages-account-admin-reports-sync-issues': () => import('./pages/account/admin/reports/sync-issues.vue'),
        'kb-pages-account-admin-reports-security-deposits': () => import('./pages/account/admin/reports/security-deposits.vue'),
        'kb-pages-account-admin-reports-occupancy': () => import('./pages/account/admin/reports/occupancy.vue'),
        'kb-pages-account-admin-reports-index': () => import('./pages/account/admin/reports/index.vue'),
        'kb-pages-account-admin-reports-new': () => import('./pages/account/admin/reports/new.vue'),
        'kb-pages-account-admin-reports-_report_id-index': () => import('./pages/account/admin/reports/_report_id/index.vue'),
        'kb-pages-account-admin-reports-_report_id-amend': () => import('./pages/account/admin/reports/_report_id/amend.vue'),
        'kb-pages-account-admin-reports-overview': () => import('./pages/account/admin/reports/overview.vue'),
        'kb-pages-user-index': () => import('./pages/user/index.vue'),
        'kb-pages-auth-login-index': () => import('./pages/auth/login/index.vue'),
        'kb-pages-auth-login-switcher': () => import('./pages/auth/login/switcher.vue'),
        'kb-pages-auth-password-forgot': () => import('./pages/auth/password/forgot.vue'),
        'kb-pages-auth-password-reset': () => import('./pages/auth/password/reset.vue'),
        'kb-pages-admin-subscriptions-index': () => import('./pages/admin/subscriptions/index.vue'),
        'kb-pages-admin-subscriptions-_subscription_id-index': () => import('./pages/admin/subscriptions/_subscription_id/index.vue'),
        'kb-pages-admin-members-index': () => import('./pages/admin/members/index.vue'),
        'kb-pages-admin-members-new': () => import('./pages/admin/members/new.vue'),
        'kb-pages-admin-members-_member_id-index': () => import('./pages/admin/members/_member_id/index.vue'),
        'kb-pages-admin-members-_member_id-delete': () => import('./pages/admin/members/_member_id/delete.vue'),
        'kb-pages-account-admin-bookings-coupons-index': () => import('./pages/account/admin/bookings/coupons/index.vue'),
        'kb-pages-account-admin-bookings-coupons-new': () => import('./pages/account/admin/bookings/coupons/new.vue'),
        'kb-pages-account-admin-bookings-coupons-_coupon_id-amend': () => import('./pages/account/admin/bookings/coupons/_coupon_id/amend.vue'),
        'kb-pages-account-admin-room-types-reviews-index': () => import('./pages/account/admin/room-types/reviews/index.vue'),
        'kb-pages-account-admin-room-types-reviews-new': () => import('./pages/account/admin/room-types/reviews/new.vue'),
        'kb-pages-account-admin-room-types-reviews-_review_id-amend': () => import('./pages/account/admin/room-types/reviews/_review_id/amend.vue'),
        'kb-pages-account-admin-room-types-index': () => import('./pages/account/admin/room-types/index.vue'),
        'kb-pages-account-admin-room-types-new': () => import('./pages/account/admin/room-types/new.vue'),
        'kb-pages-account-admin-room-types-_room_id-amend': () => import('./pages/account/admin/room-types/_room_id/amend.vue'),
        'kb-pages-account-admin-room-types-_room_id-index': () => import('./pages/account/admin/room-types/_room_id/index.vue'),
        'kb-pages-account-admin-config-integrations-index': () => import('./pages/account/admin/config/integrations/index.vue'),
        'kb-pages-account-rooms-_room_id-index': () => import('./pages/account/rooms/_room_id/index.vue'),
        'kb-pages-account-rooms-_room_id-calendar': () => import('./pages/account/rooms/_room_id/calendar.vue'),
        'kb-pages-account-bookings-index': () => import('./pages/account/bookings/index.vue'),
        'kb-pages-signup-index': () => import('./pages/signup/index.vue'),
        'kb-pages-signup-account': () => import('./pages/signup/account.vue'),
        'kb-pages-signup-settings': () => import('./pages/signup/settings.vue'),
        'kb-pages-signup-plan': () => import('./pages/signup/plan.vue'),
        'kb-pages-signup-billing': () => import('./pages/signup/billing.vue'),
        'kb-pages-signup-success': () => import('./pages/signup/success.vue'),
        'kb-pages-account-admin-reports-availability': () => import('./pages/account/admin/reports/availability.vue'),
        'kb-pages-account-admin-reports-assignment': () => import('./pages/account/admin/reports/assignment.vue'),
        'kb-pages-account-admin-bookings-fields-index': () => import('./pages/account/admin/bookings/fields/index.vue'),
        'kb-pages-account-admin-bookings-fields-new': () => import('./pages/account/admin/bookings/fields/new.vue'),
        'kb-pages-account-admin-bookings-fields-_field_id-amend': () => import('./pages/account/admin/bookings/fields/_field_id/amend.vue'),
        'kb-pages-account-admin-room-types-_room_id-rates-new': () => import('./pages/account/admin/room-types/_room_id/rates/new.vue'),
        'kb-pages-account-admin-room-types-_room_id-rates-_rate_id-amend': () => import('./pages/account/admin/room-types/_room_id/rates/_rate_id/amend.vue'),
        'kb-pages-account-admin-bookings-amend': () => import('./pages/account/admin/bookings/index.vue'),
        'kb-pages-account-admin-config-subscription-index': () => import('./pages/account/admin/config/subscription/index.vue'),
        'kb-pages-account-bookings-new': () => import('./pages/account/bookings/new.vue'),
        'kb-pages-account-admin-config-payments-index': () => import('./pages/account/admin/config/payments/index.vue'),
        'kb-pages-account-bookings-_booking_id-index': () => import('./pages/account/bookings/_booking_id/index.vue'),
        'kb-pages-account-rooms-index': () => import('./pages/account/rooms/index.vue'),
        'kb-pages-account-admin-bookings-_booking_id-customer': () => import('./pages/account/admin/bookings/_booking_id/customer.vue'),
        'kb-pages-account-admin-bookings-_booking_id-index': () => import('./pages/account/admin/bookings/_booking_id/index.vue'),
        'kb-pages-account-admin-bookings-_booking_id-amend': () => import('./pages/account/admin/bookings/_booking_id/amend.vue'),
        'kb-pages-account-admin-bookings-_booking_id-manual-payment': () => import('./pages/account/admin/bookings/_booking_id/manual-payment.vue'),
        'kb-pages-account-admin-reports-accounting': () => import('./pages/account/admin/reports/accounting.vue')
    }
})
