<template>
    <fragment>
        <nav v-if="show_header" class="navbar fixed-top navbar-inverse bg-inverse" id="topbar">
            <div class="container ml-0 m-md-auto">
                <a href="/" class="navbar-brand m-auto">
                    <img :src="require('@/images/logo-inverted.svg')" height="40" alt="Kernel Logo" />
                </a>
            </div>
            <button v-if="$account" class="btn btn-outline-secondary d-lg-none noprint" type="button" v-b-toggle.nav-collapse>
                <font-awesome-icon icon="bars" />
            </button>
        </nav>

        <b-navbar v-if="show_navbar" toggleable="lg" id="navbar" fixed="top" class="bg-faded">
            <div class="container">
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="mx-auto">
                        <fragment v-if="$user">
                            <b-nav-item-dropdown v-if="$user.is_su == 1" class="text-nowrap">
                                <template v-slot:button-content>
                                    <font-awesome-icon icon="database" fixed-width /> Admin
                                </template>
                                <b-nav-item :href="'/admin/subscriptions'">Subscriptions</b-nav-item>
                                <b-nav-item :href="'/admin/members'">Members</b-nav-item>
                            </b-nav-item-dropdown>
                            <b-nav-item :href="get_path('admin')">
                                <font-awesome-icon icon="home" fixed-width /> Dashboard
                            </b-nav-item>
                            <b-nav-item-dropdown class="text-nowrap">
                                <template v-slot:button-content>
                                    <font-awesome-icon icon="cog" fixed-width /> Settings
                                </template>
                                <fragment v-if="!is_demo">
                                    <b-nav-item :href="get_path('admin/config/subscription')" id="tourSubscription">Subscription</b-nav-item>
                                    <b-nav-item :href="get_path('admin/config/payments')">Payments</b-nav-item>
                                </fragment>
                                <b-nav-item :href="get_path('admin/config/globalsettings')" id="tourGlobalSettings">Global Settings</b-nav-item>
                                <b-nav-item :href="get_path('admin/config/emails/templates')" id="tourEmailTemplates">Email Templates</b-nav-item>
                                <b-nav-item :href="get_path('admin/config/emails/blocked')">Blocked Emails</b-nav-item>
                                <b-nav-item :href="get_path('admin/config/calendar')">Calendar</b-nav-item>
                                <b-nav-item :href="get_path('admin/config/integrations')">Integrations</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/config/user-log')}?start=${moment().subtract(1, 'month').format('YYYY-MM-DD')}&end=${moment().format('YYYY-MM-DD')}`">User Log</b-nav-item>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown class="text-nowrap">
                                <template v-slot:button-content>
                                    <font-awesome-icon icon="map-marker-alt" fixed-width /> {{ $globalsettings.plural_room_label | title }}
                                </template>
                                <b-nav-item :href="get_path('admin/room-types/categories')">Categories</b-nav-item>
                                <b-nav-item :href="get_path('admin/room-types/features')" id="tourFeatures">Features</b-nav-item>
                                <b-nav-item :href="get_path('admin/room-types/options')" id="tourOptions">Options</b-nav-item>
                                <b-nav-item :href="get_path('admin/room-types')" id="tourRooms">{{ $globalsettings.room_label | title }} Types</b-nav-item>
                                <b-nav-item :href="get_path('admin/room-types/reviews')">Reviews</b-nav-item>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown class="text-nowrap">
                                <template v-slot:button-content>
                                    <font-awesome-icon icon="calendar-alt" fixed-width /> Bookings
                                </template>
                                <b-nav-item :href="get_path('bookings/new')" id="tourBooking">Create Booking</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/bookings')}?check_out_from=${moment().format('YYYY-MM-DD')}`" id="tourBookings">View Bookings</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/bookings')}?check_in_from=${moment().format('YYYY-MM-DD')}&check_in_to=${moment().format('YYYY-MM-DD')}`" id="tourDailyArrivals">Today's {{ $globalsettings.check_in_label | title }}s</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/bookings')}?check_out_from=${moment().format('YYYY-MM-DD')}&check_out_to=${moment().format('YYYY-MM-DD')}`" >Today's {{ $globalsettings.check_out_label | title }}s</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/bookings')}?check_in_to=${moment().format('YYYY-MM-DD')}&check_out_from=${moment().format('YYYY-MM-DD')}`" >Current Bookings</b-nav-item>
                                <b-nav-item :href="get_path('admin/bookings/coupons')" id="tourCoupons">Coupons</b-nav-item>
                                <b-nav-item :href="get_path('admin/bookings/fields')" id="tourFields">Custom Fields</b-nav-item>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown class="text-nowrap">
                                <template v-slot:button-content>
                                    <font-awesome-icon icon="chart-line" fixed-width /> Reports
                                </template>
                                <b-nav-item :href="get_path('admin/reports/overview')">Overview</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/reports/availability')}?month=${moment().format('MM')}&year=${moment().format('YYYY')}`">Availability Grid</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/reports/assignment')}?month=${moment().format('MM')}&year=${moment().format('YYYY')}`">Assignment Report</b-nav-item>
                                <b-nav-item :href="get_path('admin/reports/unassigned')" v-if="$account.show_identifiers == 1">Unassigned Bookings</b-nav-item>
                                <b-nav-item :href="get_path('admin/reports/sync-issues')">Synchronisation Issues</b-nav-item>
                                <b-nav-item :href="get_path('admin/reports/security-deposit')">Security Deposits</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/reports/occupancy')}?date=${moment().format('YYYY-MM-DD')}`">Occupants on Site</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/reports/accounting')}?start=${moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')}&end=${moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}`">Accounting</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/reports/price-enquiries')}?month=${moment().format('M')}&year=${moment().format('YYYY')}`">Price Enquiries</b-nav-item>
                                <b-nav-item :href="`${get_path('admin/reports/email-logs')}?start=${moment().subtract(1, 'month').format('YYYY-MM-DD')}&end=${moment().format('YYYY-MM-DD')}`">Email Logs</b-nav-item>
                                <b-nav-item :href="get_path('admin/reports')">Custom Reports</b-nav-item>
                            </b-nav-item-dropdown>
                        </fragment>
                        <b-nav-item :href="get_path('rooms')">
                            <font-awesome-icon icon="search" fixed-width /> Search
                        </b-nav-item>
                        <b-nav-item :href="get_path('bookings')">
                            <font-awesome-icon icon="calendar-alt" fixed-width /> View Booking
                        </b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>

        <div class="text-center mt-5 mb-n5" v-if="!$user && $account && !in_iframe && !accountless_paths.includes(pathname)">
            <img v-if="$globalsettings.logo !== null" :src="$file_path+$globalsettings.logo" height="120" :alt="$globalsettings.site_name">
            <img v-else :src="require('@/images/logo.svg')" height="80" :alt="$globalsettings.site_name" />

            <div id="guest-navbar" class="mt-3">
                <span class="mr-3" v-if="$globalsettings.allow_searching === 1">
                    <a :href="get_path('rooms')">
                        <font-awesome-icon icon="search" fixed-width /> Search
                    </a>
                </span>
                <span>
                    <a :href="get_path('bookings')">
                        <font-awesome-icon icon="calendar-alt" fixed-width /> View Booking
                    </a>
                </span>
            </div>
        </div>

        <div class="container" id="maincontent">
            <div class="row">
                <div class="col-12" :class="!['/login', '/password/forgot', '/password/reset', '/login/switcher'].includes(pathname) ? 'col-lg-12' : 'col-lg-5 mx-auto'">

                    <div v-if="$user && $globalsettings.is_offline == 1" class="alert alert-warning" role="alert"><strong>Offline Mode:</strong> You are currently in offline mode, users will not be able to make bookings. <a :href="`${get_path('admin/config/globalsettings')}#offline`">Go online</a></div>

                    <div v-if="$user && $account && $account.sync_issues > 0" class="alert alert-warning" role="alert"><strong>Warning:</strong> {{$account.sync_issues}} booking synchronisation issues found. <a :href="`${get_path('admin/reports/sync-issues')}`">View Issues</a></div>

                    <div v-for="(message_list, type) in messages" :key="type" class="alert" role="alert"
                        :class="{
                            'alert-danger': type === 'error',
                            'alert-warning': type === 'warning',
                            'alert-success': type === 'success',
                            'alert-info': type === 'info'
                        }">
                        <span v-if="message_list.length === 1" v-html="message_list[0]"></span>
                        <ul v-else>
                            <li v-for="(message, index) in message_list" :key="index" v-html="message"></li>
                        </ul>
                    </div>

                    <slot></slot>

                    <component :is="onboarding_modal" v-if="$account && $account.is_onboarded == 0 && $user && $user.is_su == 0" />
                </div>
            </div>
        </div>

        <footer class="kb-footer">
            <div class="container text-muted text-center" v-if="!$user && $account && !accountless_paths.includes(pathname)">
                Powered by <a href="https://www.kernelbooking.co.uk" target="_blank">Kernel Booking</a>
            </div>
            <div class="container text-muted" v-else>
                <span class="d-none d-print-block mt-3" v-if="$account && is_customer_booking_detail">{{ $globalsettings.site_name }}, {{ $globalsettings.address_1 }}{{ $globalsettings.address_2 ? `, ${$globalsettings.address_2}` : '' }}, {{ $globalsettings.city }}, {{ $globalsettings.county }}, {{ $globalsettings.postcode }}, {{ $globalsettings.phone_number }}, {{ $globalsettings.admin_email }}</span>
                © {{ moment().format('YYYY') }} Kernel Data Ltd<span class="noprint"> <span class="m-1">•</span> <a href="https://www.kernelbooking.co.uk/terms" target="_blank">Terms</a> <span class="m-1">•</span> <a href="https://www.kernelbooking.co.uk/privacy" target="_blank">Privacy</a></span>
                <span v-if="$user" class="float-right noprint">Logged in as {{ $user.name }} <span class="m-1">•</span> <a href="/user">My Account</a> <fragment v-if="$user.no_of_accounts > 1 || $user.is_su == 1"><span class="m-1">•</span> <kb-tooltip :title="`Current client: ${$globalsettings.site_name}`"><a href="/login/switcher">Switch Client</a></kb-tooltip> </fragment><span class="m-1">•</span> <a href="/logout">Logout</a></span>
            </div>
        </footer>
    </fragment>
</template>

<script>
import Vue from 'vue'
import {Fragment} from 'vue-fragment'
import {BNavbar, BCollapse, BNavbarNav, BNavItem, BNavItemDropdown, VBToggle} from 'bootstrap-vue'
import moment from 'moment'
import KbTooltip from '@/components/Tooltip'

Vue.directive('b-toggle', VBToggle)

export default {
    components: {
        Fragment,
        BNavbar,
        BCollapse,
        BNavbarNav,
        BNavItem,
        BNavItemDropdown,
        KbTooltip
    },

    props: {
        flashMessages: [Object, Array]
    },

    data() {
        return {
            is_demo: process.env.DEMO,
            pathname: window.location.pathname,
            accountless_paths: [
                '/login',
                '/password/forgot',
                '/password/reset',
                '/login/switcher',
                '/signup',
                '/signup/success',
                '/signup/account',
                '/signup/settings',
                '/signup/plan',
                '/signup/billing'
            ],
            messages: {...this.flashMessages, ...this.$flash.getMessages()}
        }
    },

    computed: {
        show_header() {
            return this.$user || this.accountless_paths.includes(this.pathname)
        },

        show_navbar() {
            return this.$user && this.$account && !this.accountless_paths.includes(this.pathname)
        },

        onboarding_modal() {
            if (this.$account && this.$account.is_onboarded == 0 && this.$user && this.$user.is_su == 0) {
                return () => import('@/components/OnboardingModal')
            } else {
                return 'span'
            }
        },

        is_customer_booking_detail() {
            return document.querySelector('body').classList.contains('booking-detail')
        },

        in_iframe() {
            return window.self !== window.top
        }
    },

    methods: {
        moment,

        get_path(path) {
            if (this.$account) {
                return `/${this.$account.alias}/${path}`
            } else {
                return `/${path}`
            }
        }
    },

    mounted() {
        if (process.env.NODE_ENV === 'production' && (this.$user || this.accountless_paths.includes(this.pathname))) {
            window.Tawk_API = window.Tawk_API || {}
            if (this.$user) {
                Tawk_API.visitor = {
                    name: this.$user.name,
                    email: this.$user.email,
                    hash: this.$user.hash
                }
            }
            window.Tawk_API.onLoad = () => {
                [...document.querySelectorAll('iframe[title="chat widget"]')].filter(i => i.style.display === 'block').forEach((el) => {
                    el.style.display = ''
                })
            }
            const s1 = document.createElement("script")
            const s0 = document.getElementsByTagName("script")[0]
            s1.async = true
            s1.src = process.env.TAWK_SRC
            s1.charset = 'UTF-8'
            s1.setAttribute('crossorigin', '*')
            s0.parentNode.insertBefore(s1, s0)
        }

        // Custom CSS
        if (!this.$user && this.$account && !this.accountless_paths.includes(this.pathname) && window.css !== null) {
            const custom_css = document.createElement('link')
            custom_css.setAttribute('rel', 'stylesheet')
            custom_css.setAttribute('href', window.css)
            document.querySelector('head').appendChild(custom_css)
        }

        // Detect if in iframe - TODO: Clean this up
        if (window.self !== window.top) {
            document.querySelector('body').classList.add('kernel-iframe')
            document.querySelector('#maincontent').classList.remove('col-lg-10')
            document.querySelector('#maincontent').classList.add('col-lg-12')
            const height_change_listener = document.createElement('iframe')
            height_change_listener.setAttribute('id', 'height_change_listener')
            height_change_listener.setAttribute('tabindex', '-1')
            document.querySelector('body').appendChild(height_change_listener)
            if (window.integrations.facebook_page_tab.enabled) {
                window.fbAsyncInit = () => {
                    FB.init({
                        appId: window.integrations.facebook_page_tab.appId,
                        version: 'v3.2'
                    })
                    FB.Canvas.setSize({
                        height: document.querySelector('body').clientHeight + 80
                    })
                    document.querySelector('#height_change_listener').contentWindow.addEventListener('resize', () => {
                        FB.Canvas.setSize({
                            height: document.querySelector('body').clientHeight + 80
                        })
                    })
                }
                const fb_script = document.createElement('script')
                fb_script.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
                document.querySelector('head').appendChild(fb_script)
            }
            document.querySelector('#height_change_listener').contentWindow.addEventListener('resize', () => {
                const new_message = {
                    type: 'height',
                    data: document.querySelector('body').clientHeight + 80
                }
                window.parent.postMessage(JSON.stringify(new_message), '*')
            })
            document.querySelector('#height_change_listener').contentWindow.dispatchEvent(new Event('resize'))

            let path = window.location.pathname.replace(/^\//, '').split('/')
            path.shift()
            path = 'kb-' + path.join('-')
            const query = window.location.search.substring(1).split('&').reduce((p, c) => {
                const set = c.split('=')
                p[set[0]] = set[1]
                return p
            }, {})
            if (query.category) {
                path += ':' + query.category
            }
            const load_event_message = {
                type: 'load',
                data: path
            }
            window.parent.postMessage(JSON.stringify(load_event_message), '*')

            // Check iFrame cookie
            if (document.querySelector('body').classList.contains('booking-new')) {
                const cookie_array = document.cookie.split('; ')
                let has_cookie = false
                cookie_array.forEach(cookie => {
                    if (cookie.startsWith('kernel_cookie=1')) {
                        has_cookie = true
                    }
                })
                if (!has_cookie) {
                    const cookie_display = document.createElement('div')
                    cookie_display.setAttribute('id', 'cookie_display')
                    const cookie_para = document.createElement('p')
                    cookie_para.appendChild(document.createTextNode('Not seeing the booking page?'))
                    cookie_para.appendChild(document.createElement('br'))
                    const cookie_a = document.createElement('a')
                    cookie_a.setAttribute('class', 'btn btn-secondary')
                    cookie_a.setAttribute('href', window.location.href)
                    cookie_a.setAttribute('target', '_blank')
                    cookie_a.appendChild(document.createTextNode('Click here to open'))
                    cookie_para.appendChild(cookie_a)
                    cookie_display.appendChild(cookie_para)
                    document.querySelector('body').appendChild(cookie_display)
                    window.open(window.location.href, '_blank')
                }
            }
        }
    }
}
</script>
