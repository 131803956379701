export function format(value, currency = null) {
    if (currency === null) {
        currency = window.globalsettings.currency
    }
    if (currency !== 'JPY') {
        value /= 100
    }
    return new Intl.NumberFormat(window.navigator.userLanguage || window.navigator.language, {
        style: 'currency',
        currency
    }).format(value)
}

export function decimal(value, currency = null) {
    if (currency === null) {
        currency = window.globalsettings.currency
    }
    if (currency !== 'JPY') {
        value /= 100
    }
    return value
}

export function decimal_string(value, currency = null) {
    if (currency === null) {
        currency = window.globalsettings.currency
    }
    if (currency !== 'JPY') {
        value /= 100
    }
    return new Intl.NumberFormat(window.navigator.userLanguage || window.navigator.language, {
        style: 'currency',
        currency
    }).format(value).replace(/[^0-9|,|\.]/g, '')
}

export default {
    format,
    decimal,
    decimal_string
}
