export default {

    add(type, message) {
        const messages = this.getRawMessages()

        if (messages[type] === undefined) {
            messages[type] = []
        }

        messages[type].push(message)

        try {
            sessionStorage.setItem('flash', JSON.stringify(messages))
        } catch (error) {
            console.error(error)
        }
    },

    getMessages(type = null) {
        const messages = this.getRawMessages()
        if (type === null) {
            try {
                sessionStorage.setItem('flash', '{}')
            } catch (error) {
                console.error(error)
            }
            return messages
        }
        const messages_to_show = messages[type]
        delete messages[type]
        try {
            sessionStorage.setItem('flash', JSON.stringify(messages))
        } catch (error) {
            console.error(error)
        }
        return messages_to_show
    },

    getRawMessages() {
        try {
            const messages = sessionStorage.getItem('flash')
            if (messages === null) {
                return {}
            } else {
                return JSON.parse(messages)
            }
        } catch (error) {
            console.error(error)
            return {}
        }
    }

}
