import hopscotch from 'hopscotch'

export default (globalsettings) => ({
    id: 'onboarding',
    steps: [
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'The first step is to create a ' + globalsettings.room_label + '.',
            target: '#tourRooms',
            placement: 'left',
            multipage: true,
            showNextButton: false,
            nextOnTargetClick: true,
            fixedElement: true
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Click "Add New',
            target: '#tourAddRoom',
            placement: 'bottom',
            multipage: true,
            showNextButton: false,
            nextOnTargetClick: true
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Select "Available" to make the ' + globalsettings.room_label + ' available once you have saved it.',
            target: '#tourRoomAvailable',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Give your ' + globalsettings.room_label + ' a name.<br><br>Don\'t forget, you might have more than one ' + globalsettings.room_label + ' like this, in which case don\'t use a ' + globalsettings.room_label + ' number.',
            target: '#tourRoomName',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Enter a brief description and then a full description for display on your website.<br><br>Don\'t forget, you can change these as often as you want, so don\'t worry if you\'re not feeling inspired right now, you can come back to it.',
            target: '#tourRoomDescription',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'How many of these ' + globalsettings.pluralroomlabel + ' do you have?',
            target: '#tourRoomQuantity',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'When would you like them showing as "Low Availability" on your website?',
            target: '#tourRoomLowAvailability',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Now select "Occupancy" to set how many people can occupy each ' + globalsettings.room_label + '.',
            target: '#tourRoomOccupancy',
            placement: 'bottom',
            multipage: false,
            showNextButton: false,
            nextOnTargetClick: true
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'What is the minimum number of occupants for this ' + globalsettings.room_label + '?',
            target: '#tourRoomOccupancyMin',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            delay: 100
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'And the maximum on a standard booking?',
            target: '#tourRoomOccupancyMax',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Then the minimum <strong>adults</strong> you need as part of a single booking.',
            target: '#tourRoomOccupancyMinAdults',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'And the maximum <strong>adults</strong> you need as part of a single booking.',
            target: '#tourRoomOccupancyMaxAdults',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'And the maximum number of <strong>children</strong>?',
            target: '#tourRoomOccupancyMaxChildren',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'If it is possible to pay extra and add extra people on top of those already listed, you can add those here.<br><br>You can also leave it at 0 if it doesn\'t apply to this ' + globalsettings.room_label + '.<br><br>Your maximum additional people plus your maximum standard people equates to the total number of occupants for this ' + globalsettings.room_label + '.',
            target: '#tourRoomOccupancyAdditionals',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Now switch to "Identifiers"',
            target: '#tourRoomIdentifiers',
            placement: 'bottom',
            multipage: false,
            showNextButton: false,
            nextOnTargetClick: true
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Enter your ' + globalsettings.room_label + ' names or numbers for this type here.<br><br>Again, this can be adjusted later should you change your mind.',
            target: '#identifiersList',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            delay: 100
        },
        {
            title: 'Create a ' + globalsettings.room_label.charAt(0).toUpperCase() + globalsettings.room_label.slice(1),
            content: 'Then press "Save".',
            target: '#tourRoomSave',
            placement: 'bottom',
            multipage: true,
            showNextButton: false,
            nextOnTargetClick: true
        },
        {
            title: 'Create a Rate',
            content: 'To open your newly created room, you need to create your first rate. This involves setting the price for the room over any given time range.<br><br>Let\'s first view our new ' + globalsettings.room_label + '.',
            target: 'tr td a',
            placement: 'right',
            multipage: true,
            showNextButton: false,
            nextOnTargetClick: true
        },
        {
            title: 'Create a Rate',
            content: 'And then go to the Rates tab.',
            target: '#tourRates',
            placement: 'bottom',
            multipage: false,
            showNextButton: false,
            nextOnTargetClick: true
        },
        {
            title: 'Create a Rate',
            content: 'And then "Add Rate".',
            target: '#tourRatesAdd',
            placement: 'bottom',
            multipage: true,
            showNextButton: false,
            nextOnTargetClick: true,
            delay: 100
        },
        {
            title: 'Create a Rate',
            content: 'Select the dates you want the rate to run from and to.<br><br>You can of course edit this rate as often as you want, so don\'t worry too much if you are not sure on your rates yet.',
            target: '#tourRatesDates',
            placement: 'top',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a Rate',
            content: 'You can mark the ' + globalsettings.room_label + ' as Available or Closed - so if you want to stop renting a particular type of ' + globalsettings.room_label + ' for a while, you can use a Rate to do it.',
            target: '#tourRatesStatus',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a Rate',
            content: 'You can add multiple rules to charge exactly what you want, or just use a flat price per night.<br><br>Let\'s just add a basic fixed price for now, change "Disallow booking" to "Charge per night" instead and enter some prices.',
            target: '#rules',
            placement: 'top',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false
        },
        {
            title: 'Create a Rate',
            content: 'Save your new rate when you\'re done.',
            target: '#tourRatesSave',
            placement: 'bottom',
            multipage: true,
            showNextButton: false,
            nextOnTargetClick: true
        },
        {
            title: 'Create a Rate',
            content: 'Great - you have created your first rate, click on "Dashboard" and you will now see your first ' + globalsettings.plural_room_label + ' availability',
            target: '#tourDashboard',
            placement: 'bottom',
            multipage: true,
            showNextButton: false,
            nextOnTargetClick: true,
            fixedElement: true
        },
        {
            title: 'Quick Summary',
            content: 'Your first ' + globalsettings.room_label + ' is now open and available for booking! Here\'s a quick summary of some of the other features of Kernel Booking.<br><br>Your Dashboard will tell you who is going to ' + globalsettings.check_in_label + ' and ' + globalsettings.check_out_label + ' your ' + globalsettings.plural_room_label + ' today, will list your latest bookings, and will show the availability for each ' + globalsettings.room_label + ' type you have.',
            target: '#tourDashboard',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourSubscription').parentElement.classList.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'See your account and subscription settings here.',
            target: '#tourSubscription',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourGlobalSettings').parentElement.classList.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'Adjust your global settings here - currency, contact details, ' + globalsettings.check_in_label + ' and ' + globalsettings.check_out_label + ' times, auto emails and more.',
            target: '#tourGlobalSettings',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourEmailTemplates').parentElement.classList.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'Adjust your auto email templates here',
            target: '#tourEmailTemplates',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {
                document.querySelector('#navbarNavDropdown .dropdown-menu').classList.remove('show')
                document.querySelector('#tourFeatures').parentElement.classList.add('show')
            }
        },
        {
            title: 'Quick Summary',
            content: 'Searchable ' + globalsettings.room_label + ' features - such as sea view, or disabled access to help your guests find the right ' + globalsettings.room_label + ' for them.',
            target: '#tourFeatures',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourOptions').parentElement.classList.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'Options are additional extras, where you can upsell things like late checkout, pets, or a bottle of bubbly to create more revenue.',
            target: '#tourOptions',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourBooking').parentElement.classList.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'Here you can check prices if guests call you, or enter a booking manually.',
            target: '#tourBooking',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourBookings').parentElement.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'View your current bookings, with a selection of filters and search options.',
            target: '#tourBookings',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourDailyArrivals').parentElement.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'See who is arriving today - a handy list for ' + window.globalsettings.check_in_label + ' purposes.',
            target: '#tourDailyArrivals',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourCoupons').parentElement.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'Create and manage coupons should you want to use them.',
            target: '#tourCoupons',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#tourFields').parentElement.classList.add('show')}
        },
        {
            title: 'Quick Summary',
            content: 'Custom fields are for any extra information you want to collect from your customers.',
            target: '#tourFields',
            placement: 'left',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true,
            onNext: function() {document.querySelector('#navbarNavDropdown .dropdown-menu').classList.remove('show')}
        },
        {
            title: 'Quick Summary',
            content: 'A wide range of reports - from revenue to occupancy to referral sources - understand more about your business.<br><br>That\'s it! Remember you can always chat with the team at any time using the bubble at the bottom of every page.',
            target: '#tourReports',
            placement: 'bottom',
            multipage: false,
            showNextButton: true,
            nextOnTargetClick: false,
            fixedElement: true
        },
    ],
    onStart: function() {
        if (hopscotch.getCurrStepNum() == 0) {
            document.querySelector('#tourRooms').parentElement.classList.add('show')
        }
    },
    onClose: function() {
        document.querySelector('#navbarNavDropdown .dropdown-menu').classList.remove('show')
    }
})
