import axios from 'axios'
import {stringify} from 'qs'

// Default config
const config = {
    baseURL: '/api',
    paramsSerializer(params) {
        return stringify(params)
    }
}

// Add custom headers
if (window.account !== null) {
    config.headers = {
        'x-kernel-account': window.account.id
    }
}

// Create a new axios instance
const http = axios.create(config)

// Handle requests
http.interceptors.request.use((config) => {
    window.app.$root.validation = []
    return config
}, (error) => {
    return Promise.reject(error)
})

// Handle responses
http.interceptors.response.use((response) => {

    // 2xx status code
    return response

}, (error) => {

    // Non 2xx status code
    if (error.response) {
        if (error.response.status === 422) { // Validation error
            window.app.$root.validation = error.response.data.error.validation
        }
    }
    return Promise.reject(error)

})

// Custom download function
http.download = function (url, config) {
    return http.get(url, {
        responseType: 'blob',
        ...config
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link= document.createElement('a')
        link.href = url
        link.download = response.headers['content-disposition'].match(/.*filename="(.+)".*/)[1]
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
    })
}

// Export
export default http
