import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faArrowLeft,
    faArrowRight,
    faExclamationTriangle,
    faTimes,
    faCheck,
    faBars,
    faChevronLeft,
    faChevronRight,
    faCopy,
    faPlus,
    faCheckCircle,
    faExclamationCircle,
    faInfoCircle,
    faQuestionCircle,
    faUser,
    faLock,
    faDatabase,
    faCog,
    faMapMarkerAlt,
    faCalendarAlt,
    faChartLine,
    faSearch,
    faFileAlt,
    faExternalLinkAlt,
    faAsterisk,
    faMinus,
    faDownload,
    faLink
} from '@fortawesome/free-solid-svg-icons'

library.add(faHome)
library.add(faArrowLeft)
library.add(faArrowRight)
library.add(faExclamationTriangle)
library.add(faTimes)
library.add(faCheck)
library.add(faBars)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faCopy)
library.add(faPlus)
library.add(faCheckCircle)
library.add(faExclamationCircle)
library.add(faInfoCircle)
library.add(faQuestionCircle)
library.add(faUser)
library.add(faLock)
library.add(faDatabase)
library.add(faCog)
library.add(faMapMarkerAlt)
library.add(faCalendarAlt)
library.add(faChartLine)
library.add(faSearch)
library.add(faExternalLinkAlt)
library.add(faAsterisk)
library.add(faMinus)
library.add(faDownload)
library.add(faLink)

Vue.component('font-awesome-icon', FontAwesomeIcon)
