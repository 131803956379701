<template>
    <fragment>
        <span ref="tooltip" @click="$emit('click')">
            <slot></slot>
        </span>
        <b-tooltip :target="() => $refs.tooltip" :title="title" :placement="placement" :disabled="disabled"></b-tooltip>
    </fragment>
</template>

<script>
import {Fragment} from 'vue-fragment'
import {BTooltip} from 'bootstrap-vue'

export default {
    name: 'KbTooltip',

    props: {
        title: String,
        placement: {
            type: String,
            default: 'top'
        },
        disabled: Boolean
    },

    components: {
        Fragment,
        BTooltip
    }
}
</script>
